<template>
  <div>
    <!--    <div v-if="$screenIsSmall()">-->
    <!--      <div id="documentation-data">-->
    <!--        <v-form ref="form" v-model="valid_documentation">-->
    <!--          <v-card v-show="card_index === card_photo_frontal">-->
    <!--            <v-container>-->
    <!--              <v-row>-->
    <!--                <v-col align="center" justify="center">-->
    <!--                  <div>-->
    <!--                    <h3>Sacale una foto al frente de tu DNI</h3>-->
    <!--                  </div>-->
    <!--                </v-col>-->
    <!--              </v-row>-->

    <!--              <v-row>-->
    <!--                <v-col align="center" justify="center">-->
    <!--                  <v-img-->
    <!--                    contain-->
    <!--                    src="@/assets/img/take_photo_dni_front.png"-->
    <!--                    class="imageTakePhoto"-->
    <!--                  />-->
    <!--                </v-col>-->
    <!--              </v-row>-->
    <!--            </v-container>-->
    <!--          </v-card>-->

    <!--          <v-card-->
    <!--            v-show="card_index === card_photo_frontal_take"-->
    <!--            style="margin-bottom: 50%"-->
    <!--          >-->
    <!--            <v-container>-->
    <!--              <v-row>-->
    <!--                <v-col align="center" justify="center">-->
    <!--                  <div>-->
    <!--                    <h3 class="marginTopText">¿Salió bien?</h3>-->
    <!--                    <p class="subtitle">-->
    <!--                      ASEGURATE QUE LOS DATOS SE LEAN CLARAMENTE.-->
    <!--                    </p>-->
    <!--                  </div>-->
    <!--                </v-col>-->
    <!--              </v-row>-->

    <!--              <v-row>-->
    <!--                <v-col align="center" justify="center">-->
    <!--                  <v-img-->
    <!--                    contain-->
    <!--                    :src="pictures.photo_front"-->
    <!--                    class="imagePhotoTook"-->
    <!--                  />-->
    <!--                </v-col>-->
    <!--              </v-row>-->
    <!--            </v-container>-->
    <!--          </v-card>-->

    <!--          <v-card v-show="card_index === card_photo_back">-->
    <!--            <v-container>-->
    <!--              <v-row>-->
    <!--                <v-col align="center" justify="center">-->
    <!--                  <div>-->
    <!--                    <h3>Sacale una foto a la parte de atrás de tu DNI</h3>-->
    <!--                  </div>-->
    <!--                </v-col>-->
    <!--              </v-row>-->

    <!--              <v-row>-->
    <!--                <v-col align="center" justify="center">-->
    <!--                  <v-img-->
    <!--                    contain-->
    <!--                    src="@/assets/img/take_photo_dni_back.png"-->
    <!--                    class="imageTakePhoto"-->
    <!--                  />-->
    <!--                </v-col>-->
    <!--              </v-row>-->
    <!--            </v-container>-->
    <!--          </v-card>-->

    <!--          <v-card-->
    <!--            v-show="card_index === card_photo_back_take"-->
    <!--            style="margin-bottom: 50%"-->
    <!--          >-->
    <!--            <v-container>-->
    <!--              <v-row>-->
    <!--                <v-col align="center" justify="center">-->
    <!--                  <div>-->
    <!--                    <h3 class="marginTopText">¿Salió bien?</h3>-->
    <!--                    <p class="subtitle">-->
    <!--                      ASEGURATE QUE LOS DATOS SE LEAN CLARAMENTE.-->
    <!--                    </p>-->
    <!--                  </div>-->
    <!--                </v-col>-->
    <!--              </v-row>-->

    <!--              <v-row>-->
    <!--                <v-col align="center" justify="center">-->
    <!--                  <v-img-->
    <!--                    contain-->
    <!--                    class="imagePhotoTook"-->
    <!--                    :src="pictures.photo_back"-->
    <!--                  />-->
    <!--                </v-col>-->
    <!--              </v-row>-->
    <!--            </v-container>-->
    <!--          </v-card>-->

    <!--          <v-card-->
    <!--            v-show="card_index === card_selfie"-->
    <!--            style="margin-bottom: 50%"-->
    <!--          >-->
    <!--            <v-container>-->
    <!--              <v-row>-->
    <!--                <v-col align="center" justify="center">-->
    <!--                  <div>-->
    <!--                    <h3 class="marginTopText">¡Sonreí para una selfie!</h3>-->
    <!--                    <p class="subtitle">-->
    <!--                      SACATE UNA SELFIE CON EL DNI EN MANO, ASÍ COMPROBAREMOS-->
    <!--                      QUE SOS LA MISMA PERSONA.-->
    <!--                    </p>-->
    <!--                  </div>-->
    <!--                </v-col>-->
    <!--              </v-row>-->

    <!--              <v-row>-->
    <!--                <v-col align="center" justify="center">-->
    <!--                  <v-img-->
    <!--                    contain-->
    <!--                    src="@/assets/img/take_photo_selfie.png"-->
    <!--                    class="imageTakePhotoSelfie marginTopText"-->
    <!--                  />-->
    <!--                </v-col>-->
    <!--              </v-row>-->
    <!--            </v-container>-->
    <!--          </v-card>-->

    <!--          <v-card-->
    <!--            v-show="card_index === card_selfie_take"-->
    <!--            style="margin-bottom: 50%"-->
    <!--          >-->
    <!--            <v-container>-->
    <!--              <v-row>-->
    <!--                <v-col align="center" justify="center">-->
    <!--                  <div>-->
    <!--                    <h3 class="marginTopText">¿Salió bien?</h3>-->
    <!--                    <p class="subtitle">-->
    <!--                      ASEGURATE QUE SE VEA TU ROSTRO COMPLETO JUNTO CON EL-->
    <!--                      FRENTE DE TU DNI.-->
    <!--                    </p>-->
    <!--                  </div>-->
    <!--                </v-col>-->
    <!--              </v-row>-->

    <!--              <v-row>-->
    <!--                <v-col align="center" justify="center">-->
    <!--                  <v-img-->
    <!--                    contain-->
    <!--                    class="imagePhotoTook"-->
    <!--                    :src="pictures.photo_selfie"-->
    <!--                  />-->
    <!--                </v-col>-->
    <!--              </v-row>-->
    <!--            </v-container>-->
    <!--          </v-card>-->

    <!--          <v-main v-show="card_index === card_documentation_address">-->
    <!--            <v-row>-->
    <!--              <v-col align="center" justify="center">-->
    <!--                <div>-->
    <!--                  <h3>¿Dónde vivís?</h3>-->
    <!--                  <p class="subtitle">INGRESÁ TU DOMICILIO.</p>-->
    <!--                </div>-->
    <!--              </v-col>-->
    <!--            </v-row>-->

    <!--            <v-row>-->
    <!--              <v-col align="center" justify="center">-->
    <!--                <v-text-field-->
    <!--                  v-model="form.street_name"-->
    <!--                  label="Calle"-->
    <!--                  color="#FFDD00"-->
    <!--                  hint="Ejemplo: Avenida del Libertador"-->
    <!--                  :rules="[rules.required]"-->
    <!--                  required-->
    <!--                  :error-messages="errorMessages"-->
    <!--                />-->
    <!--              </v-col>-->
    <!--            </v-row>-->

    <!--            <v-row>-->
    <!--              <v-col align="center" justify="center">-->
    <!--                <v-text-field-->
    <!--                  v-model="form.street_number"-->
    <!--                  label="Altura"-->
    <!--                  color="#FFDD00"-->
    <!--                  hint="Ejemplo: 21345"-->
    <!--                  type="number"-->
    <!--                  :rules="[rules.required]"-->
    <!--                  required-->
    <!--                  :error-messages="errorMessages"-->
    <!--                />-->
    <!--              </v-col>-->
    <!--            </v-row>-->

    <!--            <v-row>-->
    <!--              <v-col>-->
    <!--                <v-text-field-->
    <!--                  v-model="form.floor"-->
    <!--                  label="Piso:"-->
    <!--                  color="#FFDD00"-->
    <!--                  hint="Ejemplo: 2"-->
    <!--                  type="number"-->
    <!--                  :rules="[]"-->
    <!--                />-->
    <!--              </v-col>-->

    <!--              <v-col>-->
    <!--                <v-text-field-->
    <!--                  v-model="form.apartment_number"-->
    <!--                  label="Dpto:"-->
    <!--                  color="#FFDD00"-->
    <!--                  hint="Ejemplo: B"-->
    <!--                  :rules="[]"-->
    <!--                />-->
    <!--              </v-col>-->
    <!--            </v-row>-->

    <!--            <v-row>-->
    <!--              <v-col>-->
    <!--                <v-text-field-->
    <!--                  v-model="form.zipcode"-->
    <!--                  label="Cód. Postal"-->
    <!--                  color="#FFDD00"-->
    <!--                  hint="Ejemplo: 1644"-->
    <!--                  type="number"-->
    <!--                  :rules="[rules.required]"-->
    <!--                  required-->
    <!--                  :error-messages="errorMessages"-->
    <!--                />-->
    <!--              </v-col>-->

    <!--              <v-col>-->
    <!--                <v-text-field-->
    <!--                  v-model="form.city"-->
    <!--                  label="Localidad"-->
    <!--                  color="#FFDD00"-->
    <!--                  hint="Ejemplo: Lanús"-->
    <!--                  :rules="[rules.required]"-->
    <!--                  required-->
    <!--                  :error-messages="errorMessages"-->
    <!--                />-->
    <!--              </v-col>-->
    <!--            </v-row>-->

    <!--            <v-row>-->
    <!--              <v-col align="center" justify="center">-->
    <!--                <v-select-->
    <!--                  v-model="provinceSelect"-->
    <!--                  :items="items"-->
    <!--                  item-value="id"-->
    <!--                  item-text="name"-->
    <!--                  label="Provincia"-->
    <!--                  color="#FFDD00"-->
    <!--                  item-color="#FFDD00"-->
    <!--                  :rules="[rules.required]"-->
    <!--                  required-->
    <!--                  :error-messages="errorMessages"-->
    <!--                />-->
    <!--              </v-col>-->
    <!--            </v-row>-->
    <!--          </v-main>-->
    <!--        </v-form>-->

    <!--        <v-footer app>-->
    <!--          <v-row v-show="showPhotoBtn">-->
    <!--            <v-col align="center" justify="center">-->
    <!--              <label for="cameraFileInput">-->
    <!--                <span class="btn btnAmarillo snap-photo">Tomar foto</span>-->
    <!--                <input-->
    <!--                  id="cameraFileInput"-->
    <!--                  ref="photo"-->
    <!--                  type="file"-->
    <!--                  accept="image/*"-->
    <!--                  capture="environment"-->
    <!--                  hidden-->
    <!--                  @change="takePhoto('photo')"-->
    <!--                />-->
    <!--              </label>-->
    <!--            </v-col>-->
    <!--          </v-row>-->
    <!--          <v-row v-show="card_index === card_selfie">-->
    <!--            <v-col align="center" justify="center">-->
    <!--              <label for="frontCameraFileInput">-->
    <!--                <span class="btn btnAmarillo snap-photo">Tomar foto</span>-->
    <!--                <input-->
    <!--                  id="frontCameraFileInput"-->
    <!--                  ref="photo_front"-->
    <!--                  type="file"-->
    <!--                  accept="image/*"-->
    <!--                  capture="user"-->
    <!--                  hidden-->
    <!--                  @change="takePhoto('photo_front')"-->
    <!--                />-->
    <!--              </label>-->
    <!--            </v-col>-->
    <!--          </v-row>-->
    <!--          <v-row v-show="showNextCardBtn">-->
    <!--            <v-col align="center" justify="center">-->
    <!--              <v-btn-->
    <!--                bottom-->
    <!--                depressed-->
    <!--                rounded-->
    <!--                class="btnAmarillo"-->
    <!--                @click="nextCard"-->
    <!--              >-->
    <!--                Continuar-->
    <!--              </v-btn>-->
    <!--            </v-col>-->
    <!--          </v-row>-->
    <!--          <v-row v-show="showBack">-->
    <!--            <v-col align="center" justify="center">-->
    <!--              <v-btn-->
    <!--                bottom-->
    <!--                depressed-->
    <!--                rounded-->
    <!--                class="btnTransparente"-->
    <!--                @click="backCard()"-->
    <!--              >-->
    <!--                Volver a tomar-->
    <!--              </v-btn>-->
    <!--            </v-col>-->
    <!--          </v-row>-->

    <!--          <v-row v-show="card_index === card_documentation_address">-->
    <!--            <v-col align="center" justify="center">-->
    <!--              <v-btn-->
    <!--                bottom-->
    <!--                depressed-->
    <!--                rounded-->
    <!--                class="btnAmarillo"-->
    <!--                :disabled="!valid_documentation"-->
    <!--                @click="next"-->
    <!--              >-->
    <!--                Continuar-->
    <!--              </v-btn>-->
    <!--            </v-col>-->
    <!--          </v-row>-->

    <!--          <v-row>-->
    <!--            <v-col align="right" justify="right">-->
    <!--              <v-btn-->
    <!--                bottom-->
    <!--                depressed-->
    <!--                rounded-->
    <!--                class="btnWppAyuda btn-help&#45;&#45;margin"-->
    <!--                @click="$store.state.redirectToWhastapp()"-->
    <!--              >-->
    <!--                Ayuda-->
    <!--                <v-img-->
    <!--                  class="imageWppAyuda"-->
    <!--                  src="@/assets/img/icon_whatsapp_ayuda.png"-->
    <!--                  alt="wpp"-->
    <!--                />-->
    <!--              </v-btn>-->
    <!--            </v-col>-->
    <!--          </v-row>-->
    <!--        </v-footer>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div v-else>-->
    <div id="documentation-data">
      <v-form ref="form" v-model="valid_documentation">
        <v-main>
          <v-row
            v-show="card_index === card_photo_frontal"
            align="center"
            align-content="center"
            justify="center"
          >
            <v-col cols="12" md="6" sm="6" align-self="center">
              <h2>{{ this.$docText["PHOTO_FRONT_TXT"] }}</h2>
              <v-col
                class="stepper-confirm__left-section-image"
                align-self="center"
                justify="center"
                style="padding-left: 50px"
              >
                <v-img
                  src="@/assets/img/yellow_pet_photo_front.svg"
                  alt="Mascota"
                  contain
                  max-height="300"
                  min-height="300"
                />
              </v-col>
            </v-col>
            <v-col cols="12" md="6" sm="6" align-self="center" align="center">
              <v-img
                contain
                max-height="300"
                min-height="300"
                max-width="60%"
                src="@/assets/img/dni_front.svg"
              />
            </v-col>
          </v-row>
          <v-row
            v-show="card_index === card_photo_frontal_take"
            align="center"
            align-content="center"
            justify="center"
          >
            <v-col cols="12" md="6" sm="6" align-self="center">
              <h2 class="marginTopText">¿Salió bien?</h2>
              <h4 class="subtitle">
                ASEGURATE QUE LOS DATOS SE LEAN CLARAMENTE.
              </h4>
              <v-col
                align="center"
                justify="center"
                class="stepper-confirm__left-section-image"
              >
                <!--                      class="imageCircleDesktop marginBottomImages"-->
                <v-img
                  src="@/assets/img/yellow_pet_photo_front_take.svg"
                  alt="logo"
                  contain
                  max-height="300"
                  min-height="300"
                />
              </v-col>
            </v-col>
            <v-col align-self="center" justify="center" cols="12" md="6" sm="6">
              <v-img class="step_doc__img--width" :src="pictures.photo_front" />
            </v-col>
          </v-row>

          <v-row
            v-show="card_index === card_photo_back"
            align-content="center"
            align="center"
            justify="center"
          >
            <v-col align-self="center" justify="center" cols="12" md="6" sm="6">
              <h2>{{ this.$docText["PHOTO_BACK_TXT"] }}</h2>
              <v-col align="center" justify="center">
                <v-img
                  class="stepper-confirm__left-section-image"
                  src="@/assets/img/yellow_pet_photo_back.svg"
                  contain
                  max-height="300"
                  min-height="300"
                  alt="Mascota"
                />
              </v-col>
            </v-col>
            <v-col
              align-self="center"
              justify="center"
              align="center"
              cols="12"
              md="6"
              sm="6"
            >
              <v-img
                contain
                max-height="300"
                min-height="300"
                src="@/assets/img/dni_back.svg"
              />
            </v-col>
          </v-row>

          <v-row
            v-show="card_index === card_photo_back_take"
            align="center"
            align-content="center"
            justify="center"
          >
            <v-col align-self="center" cols="12" md="6" sm="6">
              <h2 class="marginTopText">¿Salió bien?</h2>
              <h4 class="subtitle">
                ASEGURATE QUE LOS DATOS SE LEAN CLARAMENTE.
              </h4>
              <v-col align="center" justify="center">
                <v-img
                  class="stepper-confirm__left-section-image"
                  src="@/assets/img/yellow_pet_photo_front_take.svg"
                  alt="logo"
                  contain
                  max-height="300"
                  min-height="300"
                />
              </v-col>
            </v-col>
            <v-col align="center" justify="center" cols="12" md="6" sm="6">
              <v-img
                contain
                :src="pictures.photo_back"
                class="step_doc__img--width"
              />
            </v-col>
          </v-row>

          <v-row
            v-show="card_index === card_selfie"
            align-content="center"
            align="center"
            justify="center"
          >
            <v-col align-self="center" justify="center" cols="12" md="6" sm="6">
              <h2>¡Sonreí para una selfie!</h2>
              <h4 class="subtitle">
                {{ this.$docText["PHOTO_SELFIE_TXT"] }}
              </h4>
              <v-col align="center" justify="center">
                <v-img
                  class="stepper-confirm__left-section-image"
                  src="@/assets/img/yellow_pet_photo_selfie.svg"
                  alt="Mascota"
                  contain
                  max-height="300"
                  min-height="300"
                />
              </v-col>
            </v-col>
            <v-col
              align-self="center"
              justify="center"
              align="center"
              cols="12"
              md="6"
              sm="6"
            >
              <v-img
                contain
                max-width="70%"
                src="@/assets/img/take_photo_selfie.png"
              />
            </v-col>
          </v-row>

          <v-row
            v-show="card_index === card_selfie_take"
            align="center"
            align-content="center"
            justify="center"
          >
            <v-col align-self="center" justify="center" cols="12" md="6" sm="6">
              <h2 class="marginTopText">¿Salió bien?</h2>
              <h4 class="subtitle">
                ASEGURATE QUE SE VEA TU ROSTRO COMPLETO JUNTO CON EL FRENTE DE
                TU DNI.
              </h4>
              <v-col align="center" justify="center">
                <v-img
                  class="stepper-confirm__left-section-image"
                  src="@/assets/img/yellow_pet_selfie_take.svg"
                  alt="logo"
                  contain
                  max-height="300"
                  min-height="300"
                />
              </v-col>
            </v-col>
            <v-col
              align-self="center"
              justify="center"
              align="center"
              cols="12"
              md="6"
              sm="6"
            >
              <v-img
                contain
                class="step_doc__img--width"
                :src="pictures.photo_selfie"
              />
            </v-col>
          </v-row>

          <v-main v-show="card_index === card_documentation_address">
            <v-row align="center" align-content="center">
              <v-col class="col-sm-12 col-md-6 col-12">
                <h2>¿Dónde vivís?</h2>
                <h4 class="subtitle">INGRESÁ TU DOMICILIO.</h4>
                <v-col
                  align="center"
                  justify="center"
                  class="stepper-register__left-section-image"
                >
                  <v-img
                    src="@/assets/img/yellow_pet_address.svg"
                    alt="Mascota"
                    contain
                    max-height="300"
                    min-height="300"
                  />
                </v-col>
              </v-col>
              <v-col class="col-md-6 align-self-center col-sm-12">
                <v-row>
                  <v-col
                    align="center"
                    justify="center"
                    class="col-md-6 col-sm-6 col-12"
                  >
                    <v-text-field
                      v-model="form.street_name"
                      label="Calle"
                      color="#FFDD00"
                      hint="Ejemplo: Avenida del Libertador"
                      :rules="[rules.required]"
                      required
                      :error-messages="errorMessages"
                      tabindex="1"
                    />
                    <v-text-field
                      v-model="form.street_number"
                      class="step-address__input--margin"
                      label="Altura"
                      color="#FFDD00"
                      hint="Ejemplo: 21345"
                      type="number"
                      :rules="[rules.required]"
                      required
                      :error-messages="errorMessages"
                      tabindex="2"
                    />
                  </v-col>
                  <v-col
                    align="center"
                    justify="center"
                    class="col-md-6 col-sm-6 col-12"
                  >
                    <v-text-field
                      v-model="form.floor"
                      label="Piso:"
                      color="#FFDD00"
                      hint="Ejemplo: 2"
                      type="number"
                      :rules="[]"
                      tabindex="3"
                    />
                    <v-text-field
                      v-model="form.apartment_number"
                      class="step-address__input--margin"
                      label="Dpto:"
                      color="#FFDD00"
                      hint="Ejemplo: B"
                      :rules="[]"
                      tabindex="4"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col align="center" justify="center">
                    <v-text-field
                      v-model="form.zipcode"
                      label="Cód. Postal"
                      color="#FFDD00"
                      hint="Ejemplo: 1644"
                      type="number"
                      :rules="[rules.required]"
                      required
                      :error-messages="errorMessages"
                      tabindex="5"
                    />
                  </v-col>
                  <v-col align="center" justify="center">
                    <v-text-field
                      v-model="form.city"
                      label="Localidad"
                      color="#FFDD00"
                      hint="Ejemplo: Lanús"
                      :rules="[rules.required]"
                      required
                      :error-messages="errorMessages"
                      tabindex="6"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col align="center" justify="center" md="8">
                    <v-select
                      v-model="provinceSelect"
                      :items="items"
                      item-value="id"
                      item-text="name"
                      label="Provincia"
                      color="#FFDD00"
                      item-color="#FFDD00"
                      :rules="[rules.required]"
                      required
                      :error-messages="errorMessages"
                      tabindex="7"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-main>

          <v-row v-show="showPhotoBtn" class="step-doc__btn--margin">
            <v-col class="text-md-end text-center">
              <label for="cameraFileInput">
                <span class="btn btnAmarillo snap-photo">{{
                  this.$docText["BTN_UPLOAD"]
                }}</span>
                <input
                  id="cameraFileInput"
                  ref="photo"
                  type="file"
                  accept="image/*"
                  capture="environment"
                  hidden
                  @change="takePhoto('photo')"
                />
              </label>
            </v-col>
          </v-row>

          <v-row v-show="card_index === card_selfie">
            <v-col align="right" justify="right">
              <label for="frontCameraFileInput">
                <span class="btn btnAmarillo snap-photo">{{
                  this.$docText["BTN_UPLOAD"]
                }}</span>
                <input
                  id="frontCameraFileInput"
                  ref="photo_front"
                  type="file"
                  accept="image/*"
                  capture="user"
                  hidden
                  @change="takePhoto('photo_front')"
                />
              </label>
            </v-col>
          </v-row>

          <v-row
            v-show="
              card_index === card_photo_back_take ||
              card_index === card_photo_frontal_take ||
              card_index === card_selfie_take
            "
            align-content-md="end"
            align-md="end"
            align="center"
            align-content="center"
            justify="center"
            justify-md="end"
            style="margin-top: 70px"
          >
            <v-col
              class="col-auto text-md-end float-right order-1 order-md-0"
              align-self="end"
            >
              <v-btn
                bottom
                depressed
                rounded
                x-large
                block
                class="btnTransparente"
                @click="backCard()"
              >
                {{ this.$docText["BTN_UPLOAD_AGAIN"] }}
              </v-btn>
            </v-col>
            <v-col
              class="
                col-auto
                text-md-end
                order-md-1 order-sm-1 order-0 order-lg-1
                float-right
              "
              align-self="end"
            >
              <v-btn
                bottom
                depressed
                rounded
                x-large
                block
                class="btnAmarillo float-md-end"
                @click="nextCard"
              >
                Continuar
              </v-btn>
            </v-col>
          </v-row>

          <v-row
            v-show="card_index === card_documentation_address"
            align-content="center"
            align="center"
          >
            <v-col
              class="text-sm-center text-md-end text-center"
              justify="center"
            >
              <v-btn
                bottom
                depressed
                rounded
                x-large
                class="btnAmarillo"
                :disabled="!valid_documentation"
                @click="next"
              >
                Continuar
              </v-btn>
            </v-col>
          </v-row>
        </v-main>
      </v-form>
    </div>
  </div>
  <!--  </div>-->
</template>

<script>
import province from "@/helper/provincias.json";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AutogestionDatosChecklist",
  props: {
    card: { type: Number, default: 1 },
  },
  data: () => ({
    valid_documentation: true,
    errorMessages: "",
    form: {
      street_name: "",
      street_number: "",
      city: "",
      province: "",
      zipcode: "",
      floor: "",
      apartment_number: "",
      provinceName: "",
    },
    rules: {
      required: (value) => !!value || "Este campo es requerido.",
    },
    card_index: 1,
    card_photo_frontal: 1,
    card_photo_frontal_take: 2,
    card_photo_back: 3,
    card_photo_back_take: 4,
    card_selfie: 5,
    card_selfie_take: 6,
    card_documentation_address: 7,
    items: province,
    showBack: false,
    pictures: {
      photo_back: "",
      photo_front: "",
      photo_selfie: "",
    },
    pictureFile: {
      photo_back: "",
      photo_front: "",
      photo_selfie: "",
    },
    coords: {
      latitude: null,
      longitude: null,
      timestamp: null,
    },
    provinceSelect: "",
  }),
  computed: {
    showPhotoBtn() {
      return (
        this.card_index === this.card_photo_frontal ||
        this.card_index === this.card_photo_back
      );
    },
    showNextCardBtn() {
      return (
        this.card_index === this.card_photo_frontal_take ||
        this.card_index === this.card_photo_back_take ||
        this.card_index === this.card_selfie_take
      );
    },
  },
  watch: {
    name() {
      this.errorMessages = "";
    },
  },
  mounted() {
    //this.mainTxt = photoText[device]["MAIN_TXT"];
    //this.device = this.getIsDevice()?'CELLPHONE':'DESKTOP'
    this.card_index = this.card;
  },
  methods: {
    ...mapActions(["openLoader", "closeLoader"]),
    ...mapGetters([
      "getNeedStepSelfie",
      "getNeedStepDniFront",
      "getNeedStepDniBack",
      "getPreffix",
    ]),
    back() {
      this.$emit("send-message", "4");
    },
    next() {
      this.coords.timestamp = moment().format("DD-MM-yyyy HH:mm:ss");
      let provinceSelect = this.items[this.provinceSelect - 1];
      this.form.province = provinceSelect.id;
      this.form.provinceName = provinceSelect.name;
      let location = "";
      this.openLoader();
      let address = this.$apiserver.sendFormData(
        this.form,
        this.$apiserver.address()
      );
      if (this.coords.latitude) {
        location = this.$apiserver.sendFormData(
          this.coords,
          this.$apiserver.geolocation()
        );
      }
      this.$gtm.trackEvent({
        noninteraction: false,
        event: `${this.getPreffix()}_DOMICILIO`,
        id_person: this.$store.getters.getUserForm,
      });
      Promise.all([location, address])
        .then(() => {
          this.$store.commit("SET_PICTURES", this.pictures);
          this.$store.commit("SET_LOCATION", this.coords);
          this.$emit("send-message", "6");
        })
        .catch((error) => {
          this.$store.commit("ERROR_MODAL", true);
          console.warn(error);
        })
        .finally(() => {
          this.closeLoader();
        });
    },
    backCard() {
      this.card_index = this.card_index - 1;
    },
    takePhoto(index) {
      let photoFile = this.$refs[index]["files"][0];
      let photo = window.URL.createObjectURL(photoFile);
      if (this.card_index === this.card_photo_frontal) {
        this.pictures.photo_front = photo;
        this.pictureFile.photo_front = photoFile;
      }
      if (this.card_index === this.card_photo_back) {
        this.pictures.photo_back = photo;
        this.pictureFile.photo_back = photoFile;
      }
      if (this.card_index === this.card_selfie) {
        this.pictures.photo_selfie = photo;
        this.pictureFile.photo_selfie = photoFile;
      }
      this.card_index++;
      this.showBack = true;
    },
    nextCard() {
      let infoPhoto = this.getPhoto();
      this.$gtm.trackEvent({
        noninteraction: false,
        event: `${this.getPreffix()}_${infoPhoto["type"]}_CAM`,
        id_person: this.$store.getters.getUserForm,
      });
      this.openLoader("Enviando imagen");
      this.$apiserver
        .savePicture(infoPhoto["photo"], infoPhoto["type"])
        .then(() => {
          this.card_index = this.card_index + 1;
          this.checkLocation();
          this.showBack = false;
          this.closeLoader();
        })
        .catch((error) => {
          this.closeLoader();
          this.$store.commit("ERROR_MODAL", true);
          console.warn(error.message);
        });
    },
    checkLocation() {
      if (this.card_index !== this.card_documentation_address) {
        return;
      }
      let options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };
      navigator.geolocation.getCurrentPosition(
        this.updateLocation,
        this.errorLocation,
        options
      );
    },
    updateLocation(location) {
      let pos = location.coords;
      this.coords.latitude = location.coords.latitude;
      this.coords.longitude = location.coords.longitude;
    },
    errorLocation(err) {
      console.warn("ERROR(" + err.code + "): " + err.message);
    },
    getPhoto() {
      let photo = null;
      let type = null;
      if (this.card_index === this.card_photo_frontal_take) {
        photo = this.pictureFile.photo_front;
        type = this.$apiserver.front();
      }
      if (this.card_index === this.card_photo_back_take) {
        photo = this.pictureFile.photo_back;
        type = this.$apiserver.back();
      }
      if (this.card_index === this.card_selfie_take) {
        photo = this.pictureFile.photo_selfie;
        type = this.$apiserver.selfie();
      }
      return { photo: photo, type: type };
    },
  },
};
</script>
<style>
.snap-photo {
  border-radius: 28px;
  color: #000000;
  text-transform: uppercase;
  font-size: 0.875rem;
}

.v-select__selection--comma {
  padding: 2px 0px 0px !important;
}
</style>
